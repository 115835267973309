@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand Light'), local('Quicksand-Light'), url('quicksandlight.woff2') format('woff2'), url('quicksandlight.woff') format('woff'), url('quicksandlight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand Light Italic'), local('Quicksand-LightItalic'), url('quicksandlightitalic.woff2') format('woff2'), url('quicksandlightitalic.woff') format('woff'), url('quicksandlightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand Regular'), local('Quicksand-Regular'), url('quicksandregular.woff2') format('woff2'), url('quicksandregular.woff') format('woff'), url('quicksandregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand Italic'), local('Quicksand-Italic'), url('quicksanditalic.woff2') format('woff2'), url('quicksanditalic.woff') format('woff'), url('quicksanditalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand Bold'), local('Quicksand-Bold'), url('quicksandbold.woff2') format('woff2'), url('quicksandbold.woff') format('woff'), url('quicksandbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand Bold Italic'), local('Quicksand-BoldItalic'), url('quicksandbolditalic.woff2') format('woff2'), url('quicksandbolditalic.woff') format('woff'), url('quicksandbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
